.contact {
    font: 300 11px/1.4 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.contact .flex-padding-left {
    padding: 12vw 10vw;
}

.contact .flex-padding-right {
    padding: 0;
}

.contact .desc {
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    color: #fff;
    line-height: 20px;
}

.contact #contact-svg {
    width: 100%;
}

.contact .h1 {
    color: var(--primary-color);
}

.contact .rounded-100 {
    border-radius: 100%;
}

.contact .form-control {
    background-color: var(--bg-dark-color);
    color: #ffffffb6;
}

.contact .form-control:focus {
    background-color: var(--bg-dark-color);
    border-color: var(--primary-color);
    box-shadow: 0 0 0 0.2rem var(--primary-trans-color);
}

.contact .town {
    width: 90%;
}

.marker {
    color: var(--primary-color);
}

.contact .mobile {
    display: none;
}

@media only screen and (max-width: 500px) {
    .contact .mobile {
        display: block;
    }

    .contact .desktop {
        display: none;
    }

    .contact {
        margin-left: 0vh;
        margin-bottom: 20vh !important;
    }
}