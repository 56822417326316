.float-ripple {
    position: fixed;
    width: 100px;
    height: 100px;
    bottom: 12px;
    right: 12px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 10px;
    width: 100px;
    border-radius: 100%;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
}

.float-ripple span {
    position: relative;
    font-size: 72px;
    top: 5px;
    left: -5px;
}

.float-ripple::after {
    opacity: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: absolute;
    top: -8px;
    left: -8px;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 4px solid var(--primary-color);
    border-radius: 100%;
    -webkit-animation-name: ripple;
    animation-name: ripple;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-delay: 0s;
    animation-delay: 5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: -1;
}

.float-ripple::before {
    opacity: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: absolute;
    top: -8px;
    left: -8px;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 4px solid var(--primary-color);
    border-radius: 100%;
    -webkit-animation-name: float-ripple;
    animation-name: float-ripple;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: -1;
}

.FloatButton .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: var(--primary-color);
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    border: none;
    padding: 0;
    font-size: x-large;
}

@media only screen and (max-width: 500px) {
    .FloatButton .float {
        margin-bottom: 30px;
    }
}

@-webkit-keyframes float-ripple {
    from {
        opacity: 1;
        -webkit-transform: scale3d(0.60, 0.60, 1);
        transform: scale3d(0.40, 0.40, 1);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(1.0, 1.0, 1);
        transform: scale3d(1.0, 1.0, 1);
    }
}

@keyframes float-ripple {
    from {
        opacity: 1;
        -webkit-transform: scale3d(0.60, 0.60, 1);
        transform: scale3d(0.40, 0.40, 1);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(1.0, 1.0, 1);
        transform: scale3d(1.0, 1.0, 1);
    }
}