#sidebar {
  background-color: var(--bg-color);
  position: fixed;
}

#sidebar .navigation-item .navigation-item--link {
  color: var(--bg-dark-color);
}
#sidebar .navigation-item .active {
  color: var(--primary-color);
}

#sidebar .ex-nav .ex-nav--item + .ex-nav--item {
  margin-top: 0.5rem;
}

#sidebar .logo {
  font-size: x-large;
}

#sidebar .brand-thumb {
  text-decoration: none;
  color: var(--bg-dark-color);
  font-weight: bold;
}
#sidebar .ex-nav .ex-nav--item a {
  color: var(--bg-dark-color);
}
