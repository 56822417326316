/*——————————————
TimeLine CSS
———————————————*/
/* Base */

#content {
  margin-top: 0px;
  text-align: center;
}

section.timeline-outer {
  width: 80%;
  margin: 0 15%;
}
/* Timeline */

.timeline {
  border-left: 2px solid #171717;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
  /* color: #333; */
  /* margin: 50px auto; */
  letter-spacing: 0.5px;
  position: relative;
  line-height: 1.4em;
  padding: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
  list-style: none;
  text-align: left;
  font-weight: normal;
  font-family: "Segoe UI";
}

.timeline:after {
  display: none;
}

.timeline h1,
.timeline h2,
.timeline h5 {
  font-size: 1.2em;
  font-family: "Segoe UI";
}

.timeline .event {
  /* border-bottom: 1px solid rgba(160, 160, 160, 0.2); */
  /* padding-bottom: 15px; */
  margin-bottom: 10px;
  position: relative;
}

.timeline .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline .event .time,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .event .time {
  left: -167.5px;
  /* color: #212121; */
  white-space: pre;
  text-align: right;
  font-weight: bold;
  font-family: "Segoe UI";
  word-break: break-all;
  font-size: 1em;
  min-width: 120px;
}

.timeline .event:after {
  box-shadow: 0 0 0 2px #171717;
  left: -25px;
  background: #171717;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
  top: 5px;
}

.timeline .event div.body {
  margin: 0;
  margin-top: 10px;
}

.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
}
/**/
/*——————————————
  Responsive Stuff
  ———————————————*/

@media (max-width: 945px) {
  .timeline .event::before {
    left: 0.5px;
    top: 20px;
    min-width: 0;
    font-size: 13px;
  }
  .timeline h3 {
    font-size: 16px;
  }
  .timeline div.body {
    padding-top: 20px;
  }
  section.lab h3.card-title {
    padding: 5px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .timeline .event::before {
    left: 0.5px;
    top: 20px;
    min-width: 0;
    font-size: 13px;
  }
  .timeline .event:nth-child(1)::before,
  .timeline .event:nth-child(3)::before,
  .timeline .event:nth-child(5)::before {
    top: 38px;
  }
  .timeline h3 {
    font-size: 16px;
  }
  .timeline div.body {
    padding-top: 20px;
  }
}
/*——————————————
  others
  ———————————————*/

a.portfolio-link {
  margin: 0 auto;
  display: block;
  text-align: center;
}
