.Maintainance {
	background-color: #E8EAEA;
	text-align: center;
	font-family: 'Varela Round', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	color: #313131;
	font-size: 16px;
	padding-top: 2em;
	padding-left: 3em;
	padding-right: 3em;
}

h1 {
	font-size: 3em;
	letter-spacing: 0.4rem;
	text-transform: uppercase;
	margin-bottom: 0;
}

h2 {
	font-size: 1.4em;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	color: var(--primary-color);
	margin-bottom: 1.6em;
}

p {
	max-width: 860px;
	margin: 2em auto;
	color: #4a4b4b;
	font-size: 1.1em;
	line-height: 1.5em;
}

.illustration {
	display: block;
	max-width: 1034px;
	margin: 0 auto;
}


ul.social {
	list-style: none;
	margin: 0 auto;
	padding: 0;
}

ul.social li {
	display: inline-block;
	margin: 0;
	padding: 0;
}

ul.social li a {
	display: block;
	width: 48px;
	height: 48px;
	background: #fff;
	margin-right: 1em;
	font-size: 1.2em;
	line-height: 48px;
	border-radius: 499em;
	color: #313131;
	margin-bottom: .5em;
}

ul.social li a:hover {
	color: #fff;
	background: #35c2be;
}

@media only screen and (max-width : 1020px) {
	body {
		padding-top: 3em;
		padding-left: 2em;
		padding-right: 2em;
	}
	h1 {
		font-size: 2.6em;
	}
	h2 {
		font-size: 1.2em;
		letter-spacing: 0.2rem;
		margin-bottom: 2em;
	}
	p {
		max-width: 680px;
	}
}

@media only screen and (max-width : 768px) {
	body {
		padding-top: 1.6em;
	}
	h1 {
		font-size: 2.2em;
		letter-spacing: 0.2rem;
	}
	h2 {
		font-size: 1.1em;
		letter-spacing: 0.2rem;
	}
	p {
		margin-top: 1.5em;
		font-size: 1em;
	}	
	ul.social li a {
		width: 38px;
		height: 38px;
		font-size: 1.1em;
		line-height: 39px;
		margin-right: .5em;
	}
}

@media only screen and (max-width : 480px) {
	h1 {
		font-size: 1.6em;
	}
	h2 {
		font-size: 0.9em;
	}
	p {
		margin-top: 1.5em;
		font-size: 0.9em;
		padding-left: .5em;
		padding-right: .5em;
	}
}