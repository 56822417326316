.about {
    font: 300 11px/1.4 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.about .desc {
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    color: #fff;
    line-height: 20px;
}

.about .h1 {
    color: var(--primary-color);
}

.about .rounded-100 {
    border-radius: 100%;
}

.about .request-loader {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: auto;
    width: 500px;
    border-radius: 100%;
    background: #FFFFFF;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
}

.about .request-loader span {
    position: relative;
    font-size: 72px;
    top: 5px;
    left: -5px;
}

.about .request-loader::after {
    opacity: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: absolute;
    top: -8px;
    left: -8px;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 4px solid var(--primary-color);
    border-radius: 100%;
    -webkit-animation-name: ripple;
    animation-name: ripple;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: -1;
}

.about .request-loader::before {
    opacity: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: absolute;
    top: -8px;
    left: -8px;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 4px solid var(--primary-color);
    border-radius: 100%;
    -webkit-animation-name: ripple;
    animation-name: ripple;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: -1;
}

.about .my-mobile-image {
    display: none;
}

@-webkit-keyframes ripple {
    from {
        opacity: 1;
        -webkit-transform: scale3d(0.75, 0.75, 1);
        transform: scale3d(0.75, 0.75, 1);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(1.5, 1.5, 1);
        transform: scale3d(1.2, 1.2, 1);
    }
}

@keyframes ripple {
    from {
        opacity: 1;
        -webkit-transform: scale3d(0.75, 0.75, 1);
        transform: scale3d(0.75, 0.75, 1);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(1.2, 1.2, 1);
        transform: scale3d(1.2, 1.2, 1);
    }
}

@media only screen and (max-width: 500px) {

    .about .my-image {
        margin-top: 2.5rem;
    }

    .about .request-loader {
        width: 80vw;
        height: 80vw;

    }

    .about .img-fluid {
        padding: 1rem;
    }

    .about .img-responsive {
        width: 100%;
        height: 100%;
    }

    .about .my-image {
        display: none;
    }

    .about .my-mobile-image {
        display: block;
    }

}