.skill2 {
    font: 300 11px/1.4 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.skill2 .desc {
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    color: #fff;
    line-height: 20px;
}

.skill2 p {
    margin: 1rem 0;
}

.skill2 .h1 {
    color: var(--primary-color)
}

.skill2 .rounded-100 {
    border-radius: 100%;
}

.skill2 .tags {
    font-size: 18px;
}

.skill2 .tags .badge {
    padding: 8px;
    margin: .3em;
    font-weight: 400;
    background-color: var(--primary-color) !important;
    color: #000000;
}

.skill2 .experience {
    font-size: 18px;
}

.skill2 .bg-card {
    background-color: #181818 !important;
}

.skill2 .experience ul.key-points {
    list-style-type: none;
}

.skill2 .experience ul.key-points li {
    font-size: .8em;
    font-weight: 350;
    margin: 1em 0;
    padding-left: 4%;
}

.skill2 .experience ul.key-points li::before {
    content: "▹";
    position: absolute;
    left: 0px;
    color: var(--primary-color);
    padding-left: 4%;
}

.skill2 .work-svg {
    width: 100%;
}

.skill2 .skill-img {
    width: 80%;
}

.skill2 .bold {
    color: var(--primary-color);
    font-weight: 400;
}

.skill2 .mobile-view {
    display: none;
}

@media only screen and (max-width: 500px) {

    .skill2 .start {
        margin-top: 1.5rem !important;
    }

    .skill2 .nav.nav-tabs {
        display: contents;
    }

    .skill2 .tab-content {
        margin-top: 20px;
    }

    .skill2 .experience ul.key-points li::before {
        padding-left: 8%;
    }

    .skill2 .desktop-view {
        display: none;
    }

    .skill2 .mobile-view svg {
        height: auto;
        margin-bottom: 20px;
    }

    .skill2 .mobile-view {
        display: block;
    }
}


/* ----------------------------------------------------- */


.nav.nav-tabs {
    float: left;
    display: block;
    margin-right: 20px;
    border-bottom: 0;
    border-right: 1px solid #ddd;
    padding-right: 15px;
    font-family: "Open Sans", sans-serif;
}

.nav-tabs .nav-link {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    background: transparent;
    font-size: .9em;
    font-weight: 300;
    border-left: 2px solid #4d4d4e !important;
}

.nav-tabs .nav-link:hover {
    border: none;
    color: #fff;
}

.nav-tabs .nav-link.active {
    color: var(--primary-color);
    background-color: var(--primary-trans-color) !important;
    border: none;
    border-left: 2px solid var(--primary-color) !important;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0rem !important;
    border-top-right-radius: 0rem !important;
}

.tab-content>.active {
    display: block;
    min-height: 165px;
}

.nav.nav-tabs {
    float: left;
    display: block;
    margin-right: 20px;
    border-bottom: 0;
    border-right: 1px solid transparent;
    padding-right: 15px;
}