.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-dark-color);
}

.loader .logo {
    width: 3em;
}

.loader label {
    margin: 10px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    color: #8f8f91;
}

.loader p {
    margin: 0;
    padding: 0;
    font-size: 20px;
    color: #8f8f91;
    font-family: "Open Sans", sans-serif;
}

.loader .loader-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.loader .loader-ripple div {
    position: absolute;
    border: 3px solid var(--primary-color);
    opacity: 1;
    border-radius: 50%;
    animation: loader-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loader .loader-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes loader-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}

@media only screen and (max-width: 500px) {
    .loader {
        /* min-height: 90vh; */
    }
}