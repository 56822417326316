::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

::-webkit-scrollbar-button {
    width: 2px;
    height: 2px;
}

.work {
    font: 300 11px/1.4 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.work .desc {
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    color: #fff;
    line-height: 20px;
}

.work .h1 {
    color: var(--primary-color);
}

.work .bg-card {
    background-color: var(--bg-dark-color) !important;
}

.work .project-svg {
    width: 640px;
    height: auto;
}

.work .project-img {
    height: 10em;
}

.work .badge {
    font-size: 12px;
    padding: 8px;
    margin: .3em;
    font-weight: 600;
    background-color: var(--primary-color) !important;
    color: var(--bg-dark-color) !important;
}

.work .project-icon {
    float: right;
    align-self: flex-end;
    margin-right: -2.5rem;
    margin-top: -2rem;
    z-index: 999
}

.work .side-nav-icon {
    fill: var(--primary-color);
    margin: 0 10px;
    cursor: pointer;
}

.work .project-icon img {
    width: 3rem
}

.work .modal .modal-content .modal-header .close,
.work .modal .modal-content .modal-header,
.work .modal .modal-content .modal-footer {
    background-color: var(--bg-dark-color);
    color: #fff;
    border-bottom: 1px solid var(--bg-dark-color);
    border-top: 1px solid var(--bg-dark-color);
}

.work .modal .modal-content .modal-body {
    background-color: var(--bg-color);
    color: #fff;
    overflow: scroll;
}

.work .img-container {
    max-width: 100%;
}

.work .modal-dialog {
    margin: 15px 35px;
}

.work .modal-content {
    width: 95vw;
}

.work .page-count {
    font-size: 16px;
    color: var(--secondary-color);
}

.work .no-margin {
    margin: 0;
}

.work .link {
    font-size: 22px;
}

.work .inactive {
    display: none !important;
}

.work .active {
    display: block !important;
}

.work .overlay {
    position: absolute;
    cursor: pointer;
    font-size: x-large;
    text-align: center;
    min-width: 20vw;
    min-height: 25vh;
    padding: 70px 0;
    word-break: break-all;
}

.hovereffect {
    width: 100%;
    height: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;
}

.hovereffect .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out
}

.hovereffect img {
    position: relative;
    -webkit-transition: all .4s linear;
    transition: all .4s linear;
}

.hovereffect h2 {
    text-transform: uppercase;
    text-align: center;
    position: relative;
    font-size: 17px;
    color: var(--primary-color);
    background: var(--bg-dark-color);
    -webkit-transform: translatey(-100px);
    -ms-transform: translatey(-100px);
    transform: translatey(-100px);
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    padding: 10px;
    margin-top: 1em;
}

.hovereffect button.info {
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    margin: 8em 0 0;
    padding: 7px 14px;
}

.hovereffect button.info:hover {
    box-shadow: 0 0 5px var(--primary-color);
}

.hovereffect:hover img {
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.hovereffect:hover .overlay {
    opacity: 1;
    filter: alpha(opacity=100);
}

.hovereffect:hover h2,
.hovereffect:hover button.info {
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-transform: translatey(0);
    -webkit-transform: translatey(0);
    transform: translatey(0);
}

.hovereffect:hover button.info {
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
}

.project-menu {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    line-height: 60px;
    background-color: var(--bg-color);
}

@media only screen and (max-width: 500px) {
    .work {
        min-height: auto;
    }

    .work .hovereffect button.info {
        margin: 4em 0 0;
    }

    .work .modal-dialog {
        margin: auto;
    }

    .work .modal-content {
        width: 100%;
    }

    .work .project-svg {
        height: 30rem;
    }

    .work .overlay {
        width: 100%;
        font-size: large;
        padding: 70px 30px 0 0;
    }

    .work .second-half {
        display: none !important;
    }
}