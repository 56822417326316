/* @import '../../Assets/css/devices.min.css'; */

.projectDetails {
  font: 300 11px/1.4 "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-family: "Open Sans", sans-serif;
}

.projectDetails .flex-padding-left {
  padding: 5vw 0 12vw 10vw;
}

.projectDetails .flex-padding-right {
  padding: 2vw 0 0 2vw;
}

.projectDetails .desc {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: #fff;
  line-height: 20px;
}

.projectDetails .type {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: #fff;
  line-height: 20px;
}

.projectDetails .gallery {
  padding: 2vw 15vw 2vw 20vw;
}

.projectDetails .client-banner {
  padding: 2vw 0 2vw 10vw;
}

.projectDetails .header {
  padding: 2vw 10vw;
}

.projectDetails .col-sm-2 p,
.projectDetails .col-sm-3 p,
.projectDetails .col-sm-4 p {
  word-wrap: break-word;
  /* color: #fff; */
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  margin: 10px 0;
  padding: 0;
}

.projectDetails .col-sm-2 .title-type,
.projectDetails .col-sm-3 .title-type,
.projectDetails .col-sm-4 .title-type {
  /* color: #fff; */
  font-weight: bold;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
}

.projectDetails .scroll-indicator {
  width: 20px;
  height: 30px;
  border: 2px solid var(--primary-color);
  border-radius: 50px;
  position: relative;
  box-shadow: 0px 0px 55px -5px var(--primary-color);
  margin: 12vw 0 0 17vw;
}

.projectDetails .scroll-indicator::before {
  content: "";
  position: absolute;
  top: 0.2em;
  left: 50%;
  width: 5px;
  height: 5px;
  background-color: var(--primary-color);
  border-radius: 50%;
  transform: translate(-50%, 0);
  animation: scrollAnim 1.8s 0.5s infinite ease-in;
  box-shadow: 0px 0px 35px -5px var(--primary-color);
}

/* Animation */
@keyframes scrollAnim {
  0% {
    transform: translate(-50%, 0) rotateX(0);
    opacity: 0;
  }

  5% {
    opacity: 0.5;
  }

  15% {
    opacity: 1;
  }

  90%,
  100% {
    opacity: 0;
  }

  100% {
    transform: translate(-50%, 30px) rotateX(30deg);
  }
}

.carousel {
  position: relative;
}

.carousel-item img {
  object-fit: cover;
}

#carousel-thumbs {
  background: rgba(255, 255, 255, 0.3);
  bottom: 0;
  left: 0;
  padding: 0 50px;
  right: 0;
}

#carousel-thumbs img {
  border: 5px solid transparent;
  cursor: pointer;
}

#carousel-thumbs img:hover {
  border-color: rgba(255, 255, 255, 0.3);
}

#carousel-thumbs .selected img {
  border-color: #fff;
}

.carousel-control-prev,
.carousel-control-next {
  width: 50px;
}

@media all and (max-width: 767px) {
  .carousel-container #carousel-thumbs img {
    border-width: 3px;
  }
}

@media all and (min-width: 576px) {
  .carousel-container #carousel-thumbs {
    position: absolute;
  }
}

@media all and (max-width: 576px) {
  .carousel-container #carousel-thumbs {
    background: #ccccce;
  }
}

@media only screen and (max-width: 1024px) {
  .gallery {
    margin-bottom: 50px;
  }
}

.phone {
  border: 40px solid #121212;
  border-width: 55px 7px;
  border-radius: 40px;
  margin: 50px auto;
  overflow: hidden;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.phone iframe {
  border: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

/*Different Perspectives*/

/* Table View */
.phone.view_1 {
  -webkit-transform: rotateX(50deg) rotateY(0deg) rotateZ(-30deg);
  transform: rotateX(50deg) rotateY(0deg) rotateZ(-30deg);
  box-shadow: -3px 3px 0 #000, -6px 6px 0 #000, -9px 9px 0 #000,
    -12px 12px 0 #000, -14px 10px 20px #000;
}
/*  Front View */
.phone.view_2 {
  -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  box-shadow: 0px 3px 0 #000, 0px 4px 0 #000, 0px 5px 0 #000, 0px 7px 0 #000,
    0px 10px 20px #000;
}
