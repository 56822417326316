.home {
    font: 300 11px/1.4 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.home .m--card {
    background-color: transparent;
    box-shadow: none;
    color: #ffffff;
}


.home .tags {
    font-size: 20px;
    color: var(--secondary-color);
    margin: 0;
}

.home .left-side {
    margin-top: auto;
    margin-bottom: auto;
}


.home .web-developer-svg {
    width: 100%;
    height: 100%;
}

.home .rounded-100 {
    border-radius: 100%;
}

@media only screen and (max-width: 500px) {
    .home .left-side {
        margin-top: 2rem;
        margin-bottom: auto;
    }
}