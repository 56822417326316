.App {
  overflow: hidden;
}

a {
  color: var(--secondary-color);
  text-decoration: none;
}

a:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

.btn-success {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-outline-success {
  color: var(--primary-color) !important;
  background-color: transparent;
  background-image: none;
  border-color: var(--primary-color) !important;
}

.btn-outline-success:hover {
  color: #fff !important;
  background-color: var(--primary-trans-color);
  border-color: var(--primary-color);
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: var(--primary-color);
}

.badge {
  padding: 8px;
  margin: 0.3em;
  font-weight: 400;
  background-color: var(--primary-color) !important;
}

.github-link,
.blog-link {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 99;
}

.github-link .triangle,
.blog-link .triangle {
  position: fixed;
  top: -17px;
  right: -85px;
  width: 0;
  height: 0;
  border-bottom: 100px solid var(--bg-color);
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  color: #fff;
  -ms-transform: rotate(45deg);
  /* IE 9 */
  transform: rotate(45deg);
  /* Standard syntax */
}

.github-link .triangle .text,
.blog-link .triangle .text {
  position: fixed;
  font-size: 20px;
  top: 50px;
  right: -10px;
  color: var(--primary-color);
  -ms-transform: rotate(5deg);
  /* IE 9 */
  transform: rotate(5deg);
}

.github-link .triangle .text {
  color: #fff;
  top: 45px;
  font-size: 26px;
  -ms-transform: rotate(180deg);
  /* IE 9 */
  transform: rotate(320deg);
}

#pageBody {
  padding-right: 3vw;
}
